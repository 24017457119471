@import "../../node_modules/@coreui/coreui/scss/variables/colors";
// Variable overrides
$grid-gutter-width: 20px !default;

$sidebar-width: 300px;

// Sidebar


$sidebar-color:                       #fff !default;
$sidebar-bg:                          $gray-800 !default;

$sidebar-nav-link-active-color:       #fff !default;
$sidebar-nav-link-active-bg:          lighten($sidebar-bg, 5%) !default;

$navbar-bg: #f0f3f5;
