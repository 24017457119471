@mixin make-margins-and-paddings($screen-size, $size, $loop-count) { // xs, 10px, 12: lm-xs-t1

  $types: margin padding;

  @each $type in $types {

      $t: str-slice($type, 1, 1); // $t is minified of '$type', m for minified 'margin' word. Same as paddings...

      $directions: top right bottom left;

      @each $direction in $directions {
          $d: str-slice($direction, 1, 1); // $d is minified of '$directions',t for minified 'top' word. Same as other directions...

          @for $i from 0 through $loop-count {
              .l#{$t}-#{$screen-size}-#{$d}#{$i}{  // margin-xs-top-10 = margin-top, xs and above sizes, 10px
                  #{$type}-#{$direction}: ($size * $i) !important;
              }
          }
      }
  }
}

@mixin form-validation-state($state, $color) {

  // submit butonuna basıldıktan sonraki durum start
  .checked-form {
    .#{$state}-feedback {
      display: none;
      width: 100%;
      margin-top: $form-feedback-margin-top;
      font-size: $form-feedback-font-size;
      color: $color;
    }

    .#{$state}-tooltip {
      position: absolute;
      top: 100%;
      z-index: 5;
      display: none;
      max-width: 100%; // Contain to parent when possible
      padding: .5rem;
      margin-top: .1rem;
      font-size: .875rem;
      line-height: 1;
      color: $white;
      background-color: rgba($color, .8);
      border-radius: .2rem;
    }

    .form-control { // tüm inputların validation durumlarının kontrolü
      .was-validated &:#{$state},
      &.ng-#{$state}, .ng-#{$state} {
        border-color: $color;

        &:focus {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
        }
      }
    }

    .custom-form-control { // içinde input olan custom reactive form component'lerin durumlarının kontrolü
      .was-validated &:#{$state},
      &.ng-#{$state}, .ng-#{$state} {

        .form-control {
          border-color: $color;

          &:focus {
            border-color: $color;
            box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
          }

          ~ .#{$state}-feedback,
          ~ .#{$state}-tooltip {
            display: block;
          }
        }

      }
    }



    // .custom-select {
    //   .was-validated &:#{$state},
    //   &.ng-#{$state} {
    //     border-color: $color;

    //     &:focus {
    //       border-color: $color;
    //       box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
    //     }

    //     ~ .#{$state}-feedback,
    //     ~ .#{$state}-tooltip {
    //       display: block;
    //     }
    //   }
    // }

    .ng-select {
      .was-validated &:#{$state},
      &.ng-#{$state} {
        .ng-select-container {
          border-color: $color;
        }

        &:focus, &.ng-select-focused {
          // background: rgb(19, 18, 18)!important;
          .ng-select-container {
            border-color: $color;
            box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
          }
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
        }
      }
    }

    // select.form-control, app-select2 {
    //   // .was-validated &:#{$state},
    //   &.ng-#{$state} {
    //     // border: 1px solid red;
    //     & + .select2 .select2-selection,
    //     & .select2 .select2-selection  {
    //       border-color: $color;
    //     }
    //     & + .select2.select2-container--focus .select2-selection,
    //     & .select2.select2-container--focus .select2-selection {
    //       box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
    //     }

    //     ~ .#{$state}-feedback,
    //     ~ .#{$state}-tooltip {
    //       display: block;
    //     }
    //   }
    // }


    .form-control-file {
      .was-validated &:#{$state},
      &.ng-#{$state} {
        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
        }
      }
    }

    .form-check-input {
      .was-validated &:#{$state},
      &.ng-#{$state} {
        ~ .form-check-label {
          color: $color;
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
        }
      }
    }

    .custom-control-input {
      .was-validated &:#{$state},
      &.ng-#{$state} {
        ~ .custom-control-label {
          color: $color;

          &::before {
            background-color: lighten($color, 25%);
          }
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
        }

        &:checked {
          ~ .custom-control-label::before {
            @include gradient-bg(lighten($color, 10%));
          }
        }

        &:focus {
          ~ .custom-control-label::before {
            box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-focus-width rgba($color, .25);
          }
        }
      }
    }

    // custom file
    .custom-file-input {
      .was-validated &:#{$state},
      &.ng-#{$state} {
        ~ .custom-file-label {
          border-color: $color;

          &::before { border-color: inherit; }
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
        }

        &:focus {
          ~ .custom-file-label {
            box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
          }
        }
      }
    }
  }
  // submit butonuna basıldıktan sonraki durum end

  // submit butonuna basılmadan önceki durum start
  // invalid form'lar start (required alanların submit'e basılmadan önce belirtilmesi için)
  .form-control {
    .was-validated &:#{$state},
    &.ng-invalid {
      border-color: $color;

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
      }
    }
  }

  .ng-select {
    .was-validated &:#{$state},
    &.ng-invalid {
      .ng-select-container {
        border-color: $color;
      }

      &:focus, &.ng-select-focused {
        // background: rgb(19, 18, 18)!important;
        .ng-select-container {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }
  }
  // invalid form'lar end
   // submit butonuna basılmadan önceki durum end

}