[type=button], [type=reset], [type=submit], button { // a elementlerinin button şeklinde görünmesini engelleyen bir bug için çözüm
  -webkit-appearance: none;
}

.btn-loading:after,.dropdown-menu .dropdown-toggle>a:after,.dropdown-toggle:after {
  content: ""
}

.btn-loading {
  position: relative;
  cursor: default;
  color: transparent!important;
  pointer-events: none;
}

.btn-loading:after,.btn-loading:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.28em;
  height: 1.28em;
  margin: -.64em 0 0 -.64em
}

.btn-loading:before {
  content: '';
  border: 2px solid rgba(0,0,0,.15);
  border-radius: 9999px
}

.btn-loading:after {
  -webkit-animation: button-loading .6s linear;
  animation: button-loading .6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border: 2px solid;
  border-color: #fff transparent transparent;
  border-radius: 9999px;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent
}

@-webkit-keyframes button-loading {
  from {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@keyframes button-loading {
  from {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}