@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);

.bigger-font-switch.switch-label .switch-slider::after {
  font-size: 13px;
}

.form-group {
  align-items: center;
}

.form-control[readonly] {
  background-color: #fff;
}


// checkbox styling start
.checkbox {
  & input[type="checkbox"] {
    opacity: 0;
  }

  & label {
    position: relative;
    display: inline-block;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 22px;
  }

  & label::before,
  & label::after {
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  & label::before{
    height: 16px;
    width: 16px;

    border: 1px solid;
    left: 0px;

    /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
    top: 3px;
  }

  /*Checkmark of the fake checkbox*/
  & label::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;

    transform: rotate(-45deg);

    left: 4px;
    top: 7px;
  }

  /*Hide the checkmark by default*/
  & input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  & input[type="checkbox"]:checked + label::after {
    content: "";
  }

  /*Adding focus styles on the outer-box of the fake checkbox*/
  & input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
}
// checkbox styling end



.ng-select.ng-select-disabled>.ng-select-container {
  background-color: $input-disabled-bg;
}