// Here you can add other styles

$zindex-element-loading: 1000 !default;
$element-loading-spinner-size: 32px !default;
$dimmer-bg: rgba(255, 255, 255, 0.8) !default;
$dimmer-spinner-bg: rgba(0, 0, 0, 0.1) !default;
$dimmer-spinner-color: #767676 !default;
$dimmer-bg-inverted: rgba(0, 0, 0, 0.45) !default;
$dimmer-spinner-bg-inverted: rgba(255, 255, 255, 0.2) !default;
$dimmer-spinner-color-inverted: #fff !default;

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(form-spin) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.element-loading {
  position: relative;

  cursor: default;
  user-select: none;
  pointer-events: none;

  &:before {
    position: absolute;
    z-index: $zindex-element-loading;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    content: "";

    background: $dimmer-bg;
  }

  &:after {
    position: absolute;
    z-index: $zindex-element-loading + 1;
    top: 50%;
    left: 50%;

    display: block;

    width: $element-loading-spinner-size;
    height: $element-loading-spinner-size;
    margin: (-$element-loading-spinner-size/2) 0 0
      (-$element-loading-spinner-size/2);

    content: "";
    animation: form-spin 0.6s linear;
    animation-iteration-count: infinite;

    border-width: 2px;
    border-style: solid;
    border-color: $dimmer-spinner-color $dimmer-spinner-bg $dimmer-spinner-bg;
    border-radius: 32px;
  }

  &.element-loading-inverted {
    &:before {
      background: $dimmer-bg-inverted;
    }
    &:after {
      border-color: $dimmer-spinner-color-inverted $dimmer-spinner-bg-inverted
        $dimmer-spinner-bg-inverted;
    }
  }
}

.btn-dynamic-form-remove {
  right: -10px;
  top: -10px;
}

//report table
.report-table {
  border-spacing: 0px;
  width: 100%;
  max-width: 100%;
  tr {
    background-color: #ffffff;
    padding: 8px;
    margin: 0px;
    font-weight: normal;
    text-align: center;
    td {
      padding: 8px;
      margin: 0px;
      vertical-align: middle;
      text-align: left;
    }
  }
}

.rb-bordered-table {
  tr {
    td {
      padding: 8px;
      margin: 0px;
      vertical-align: middle;
      text-align: left;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    th {
      padding: 8px;
      margin: 0px;
      vertical-align: middle;
      text-align: left;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
  }
}

.centered-table {
  tr {
    td {
      text-align: center;
    }
    th {
      text-align: center;
    }
  }
}

.text-center {
  td {
    text-align: center !important;
  }
}

.borderless {
  border: 0 !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}
.border {
  border: 1px solid black;
}
.border-l {
  border-left: 1px solid black;
}
.border-r {
  border-right: 1px solid black;
}
.border-t {
  border-top: 1px solid black;
}
.border-b {
  border-bottom: 1px solid black;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.vertical-text {
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.font-weight-normal {
  font-weight: normal !important;
}
.w-50 {
  width: 50% !important;
  max-width: 50% !important;
  min-width: 50% !important;
}
.w-15 {
  width: 15% !important;
  max-width: 15% !important;
  min-width: 15% !important;
}
.w-5 {
  width: 5% !important;
  max-width: 5% !important;
  min-width: 5% !important;
}
