.bs-datepicker-head {
  bs-datepicker-navigation-view {
    display: flex;
    justify-content: space-between;
  }
}


select {
  & + .select2 {
    font-size: 0.875rem;
    .select2-selection {
      outline: 0;
      color: $input-color;
      background-color: #fff;
      border-radius: 0.25rem;
      border-color: $input-border-color;
    }
    &:focus, &:active, &.select2-container--open{
      outline: 0;
      border-color: $input-focus-border-color;
      border-radius: 0.25rem;
      .select2-selection  {
        border-color: $input-focus-border-color;
      }
    }
    &.select2-container--focus {
      .select2-selection {
        box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
        @include transition($input-transition);
        border-color: $input-focus-border-color;
        border-radius: 0.25rem;
      }

    }
    &.select2-container--open {
      box-shadow: none;
      @include transition($input-transition);
    }

    @include form-control-focus();
  }
}

.select2-container {
  font-size: 0.875rem;
  .select2-selection {
    height: 35px;
    &>span,
    & .select2-selection__rendered,
    & .select2-selection__arrow {
      line-height: 34px;
      color: $input-color;
    }

    & .select2-selection__rendered {
      padding: 0 0 0 0.75rem;
    }

    & .select2-selection__arrow {
      height: 34px;
    }
  }
  .select2-dropdown {
    border: 1px solid $input-focus-border-color;
    border-top: none;
  }
  .select2-search__field{
    border: 1px solid $input-border-color;
    border-radius: 0.25rem;
    &:focus {
      border-color: $input-focus-border-color;
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(32,168,216,.25);
    }
  }
}

.ng-select {

  .ng-select-container {
    border-color: $input-border-color;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .ng-input, .ng-value {
      color: $input-color;
    }
  }

  // .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container
  // .ng-select.ng-select-opened>.ng-select-container:hover
  &.ng-select-focused, &.ng-select-focused:not(.ng-select-opened) {
    .ng-select-container, .ng-select-container:hover {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
    // &:not(.ng-select-opened)>.ng-select-container {
    //   border-color: red!important;
    // }
  }
}


// ng2-file-upload start
.my-drop-zone {
  border: dotted 3px lightgray;
}

.nv-file-over {
  border: dotted 3px red;
}

/* Default class applied to drop zones on over */
.another-file-over-class {
  border: dotted 3px green;
}
// ng2-file-upload end


