// margin and padding creator | ex: lm-xs-t1: l=layout, m=margin or p=padding, xs=size, t=top, 1=10px
@include media-breakpoint-up(xs) {
  @include make-margins-and-paddings(xs, 10px, 12);
}
@include media-breakpoint-up(sm) {
  @include make-margins-and-paddings(sm, 10px, 12);
}
@include media-breakpoint-up(md) {
  @include make-margins-and-paddings(md, 10px, 12);
}
@include media-breakpoint-up(lg) {
  @include make-margins-and-paddings(lg, 10px, 12);
}
@include media-breakpoint-up(xl) {
  @include make-margins-and-paddings(xl, 10px, 12);
}

a {
  cursor: pointer;
}

.tab-pane .card-body {
  padding: 20px 0;
}

@include media-breakpoint-down(sm) {
  .main .container-fluid {
    padding: 0 10px;
  }
}

.navbar-brand:not(.custom-navbar-brand){
  display: none;
}


.invalid-animation {
  outline-color: red;
  /* also need animation and -moz-animation */
  animation: shake .5s linear;
  -webkit-animation: shake .5s linear;
}

/* also need keyframes and -moz-keyframes */
@keyframes shake {
  8%, 41% {
      -webkit-transform: translateX(-5px);
  }
  25%, 58% {
      -webkit-transform: translateX(5px);
  }
  75% {
      -webkit-transform: translateX(-2px);
  }
  92% {
      -webkit-transform: translateX(2px);
  }
  0%, 100% {
      -webkit-transform: translateX(0);
  }
}